/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {useCookies} from 'react-cookie'
import {Outlet, useSearchParams} from 'react-router-dom'

const AuthLayout = () => {
  const [cookies] = useCookies(['_bt'])
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  useEffect(() => {
    if (cookies._bt && !searchParams.get('verify-changed-email')) {
      window.location.href = 'https://persona.app.diawan.id'
      // window.location.href = 'http://localhost:3001'
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100 bg-light'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 bg-light'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid bg-light'>
          {/* begin::Wrapper */}
          <div className='w-100 w-lg-600px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {AuthLayout}
