/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getPhoneCode, getUserByToken, register} from '../core/_requests'
import {Link, useNavigate} from 'react-router-dom'
import {KTSVG, KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import ReCAPTCHA from 'react-google-recaptcha'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {useQuery} from 'react-query'
import {AxiosResponse} from 'axios'

const initialValues = {
  fullName: '',
  email: '',
  phoneNumber: '',
  password: '',
  passwordConfirmation: '',
  acceptTerms: false,
  acceptCaptcha: false,
}

const registrationSchema = Yup.object().shape({
  fullName: Yup.string().required('Nama Lengkap wajib diisi'),
  phoneNumber: Yup.string().required('Nomor Handphone wajib diisi'),
  email: Yup.string().email('Email yang anda masukkan tidak valid').required('Email wajib diisi'),
  password: Yup.string()
    .min(8, 'Password harus terdiri dari minimal 8 karakter')
    .required('Password wajib diisi')
    .matches(/[a-z]/, 'Minimal satu karakter huruf kecil')
    .matches(/[A-Z]/, 'Minimal satu karakter huruf besar')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Minimal satu simbol'),
  passwordConfirmation: Yup.string()
    .min(8, 'Password harus terdiri dari minimal 8 karakter')
    .required('Password wajib diisi')
    .oneOf([Yup.ref('password')], 'Konfirmasi password tidak sesuai'),
  acceptTerms: Yup.bool().isTrue('Kamu harus menyetujui syarat dan ketentuan yang berlaku'),
  acceptCaptcha: Yup.bool().isTrue('Kamu harus menyetujui reCAPTCHA'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const [captcha, setCaptcha] = useState(true)
  const [hasErrors, sethasErrors] = useState<object | unknown>()
  const [success, setSuccess] = useState<string>()
  const [hiddenPass, setHiddenPass] = useState(true)
  const [hiddenPassConfirm, setHiddenPassConfirm] = useState(true)
  const [countryCode, setCountryCode] = useState<any>([])
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      sethasErrors('')
      try {
        const {data: auth} = await register(
          values.email,
          values.fullName,
          values.phoneNumber,
          values.password,
          values.passwordConfirmation
        )
        setSuccess(auth.message)
        setLoading(false)
        setTimeout(() => {
          navigate('/')
        }, 4000)
      } catch (error: any) {
        setLoading(false)
        sethasErrors(error)
        console.log(error)
      }
    },
  })

  useQuery('get-phone-code', getPhoneCode, {
    onSuccess: (data: AxiosResponse) => {
      setCountryCode(data.data.data.map((data) => data.iso2))
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [countryCode])

  return (
    <form className='form w-100' onSubmit={formik.handleSubmit} id='registration-form'>
      <div className='card card-custom card-flush bg-white'>
        {/* begin::body */}
        <div className='card-body'>
          {/* begin::Logo Title */}
          <div className='mb-10 text-center'>
            <img
              className='mb-3 w-md-50px w-40px'
              src={toAbsoluteUrl('/media/diawan/diawan-logo.png')}
              alt='diawan logo'
            />
            <h1 className='text-dark fw-bolder mb-3'>Bergabung</h1>
            <div className='text-gray-500 fw-semibold fs-6'>
              Selamat bergabung dengan keluarga diawan
            </div>
          </div>
          {/* end::Logo Title */}

          {/* begin::Alert */}
          {success ? (
            <div className='alert alert-success d-flex align-items-center gap-3' role='alert'>
              <KTSVG
                path='/media/icons/duotune/general/gen043.svg'
                className='svg-icon svg-icon-2x svg-icon-success'
              />
              <div className='text-dark'>
                Register berhasil, silahkan cek email untuk melakukan aktivasi
              </div>
            </div>
          ) : (
            ''
          )}
          {/* end::Alert */}

          {/* begin::Alert */}
          {hasErrors ? (
            <div className='alert alert-danger d-flex align-items-center gap-3' role='alert'>
              <KTSVG
                path='/media/icons/duotune/general/gen044.svg'
                className='svg-icon svg-icon-2x svg-icon-danger'
              />
              <div className='text-dark'>{(hasErrors as any).response.data.message}</div>
            </div>
          ) : (
            ''
          )}
          {/* end::Alert */}

          {/* begin::Login Input Field */}
          <div className='mb-5'>
            <div className='form-floating'>
              <input
                id='full-name-input'
                type='text'
                placeholder='name'
                {...formik.getFieldProps('fullName')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.fullName && formik.errors.fullName},
                  {
                    'is-valid': formik.touched.fullName && !formik.errors.fullName,
                  }
                )}
              />
              <label htmlFor='full-name-input' className='form-label'>
                Nama Lengkap
              </label>
            </div>
            {formik.touched.fullName && formik.errors.fullName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.fullName}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-5'>
            <div className='form-floating'>
              {countryCode.length !== 0 && (
                <PhoneInput
                  id='phone-number-input'
                  international
                  value={formik.values.phoneNumber}
                  defaultCountry='ID'
                  countries={countryCode}
                  className={clsx(
                    'form-control',
                    {'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber},
                    {
                      'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
                    }
                  )}
                  name='numberPhone'
                  placeholder='Nomor Handphone'
                  countryCallingCodeEditable={false}
                  // onChange={(value: string) => setPhoneNumber(value)}
                  onChange={(value) => {
                    formik.setFieldValue('phoneNumber', value !== undefined ? value : '')
                  }}
                />
              )}
              <label htmlFor='phone-number-input' className='form-label'>
                Nomor Handphone
              </label>
            </div>

            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.phoneNumber}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-5'>
            <div className='form-floating'>
              <input
                id='email-input'
                type='email'
                placeholder='testing@example.com'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              <label htmlFor='email-input' className='form-label'>
                Email
              </label>
            </div>
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-5'>
            <div
              className={clsx(
                'input-group p-0 form-control bg-transparent',
                {
                  'border-danger': formik.touched.password && formik.errors.password,
                },
                {
                  'border-success': formik.touched.password && !formik.errors.password,
                }
              )}
            >
              <div className='form-floating'>
                <input
                  id='password-input'
                  type={hiddenPass ? 'password' : 'text'}
                  placeholder='password'
                  {...formik.getFieldProps('password')}
                  className='form-control border-0 bg-transparent'
                />
                <label htmlFor='password-input' className='form-label'>
                  Password
                </label>
              </div>
              <span
                className='input-group-text bg-transparent border-0'
                id='basic-addon2'
                onClick={() => setHiddenPass(!hiddenPass)}
              >
                <KTIcon
                  iconName={hiddenPass ? 'eye-slash' : 'eye'}
                  iconType='solid'
                  className='fs-1 cursor-pointer'
                />
              </span>
            </div>
            <div className='ms-2 mt-1 text-gray-600 fs-7'>
              Minimal 8 karakter dengan kombinasi huruf, numerik dan simbol.
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-5'>
            <div
              className={clsx(
                'input-group p-0 form-control bg-transparent',
                {
                  'border-danger':
                    formik.touched.passwordConfirmation && formik.errors.passwordConfirmation,
                },
                {
                  'border-success':
                    formik.touched.passwordConfirmation && !formik.errors.passwordConfirmation,
                }
              )}
            >
              <div className='form-floating'>
                <input
                  id='password-confirmation-input'
                  type={hiddenPassConfirm ? 'password' : 'text'}
                  placeholder='password'
                  {...formik.getFieldProps('passwordConfirmation')}
                  className='form-control border-0 bg-transparent'
                />
                <label htmlFor='password-confirmation-input' className='form-label'>
                  Konfirmasi Password
                </label>
              </div>
              <span
                className='input-group-text bg-transparent border-0'
                id='basic-addon2'
                onClick={() => setHiddenPassConfirm(!hiddenPassConfirm)}
              >
                <KTIcon
                  iconName={hiddenPassConfirm ? 'eye-slash' : 'eye'}
                  iconType='solid'
                  className='fs-1 cursor-pointer'
                />
              </span>
            </div>
            <div className='ms-2 mt-1 text-gray-600 fs-7'>
              Minimal 8 karakter dengan kombinasi huruf, numerik dan simbol.
            </div>
            {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.passwordConfirmation}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-5'>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                id='terms-condition'
                {...formik.getFieldProps('acceptTerms')}
              />
              <label className='form-check-label text-dark' htmlFor='terms-condition'>
                Saya setuju dengan syarat dan ketentuan yang berlaku.
              </label>
            </div>
            {formik.touched.acceptTerms && formik.errors.acceptTerms && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.acceptTerms}</span>
                </div>
              </div>
            )}
          </div>

          {/* reCaptcha */}
          <div className='mb-5'>
            <ReCAPTCHA
              sitekey='6LeL-xIpAAAAANZJ0UTeyeUkvHIAUZTEWfKSajPn'
              // sitekey='6Lf3G_goAAAAAOR5mdkyZwLWiAIPBAb9FzwyslGI'
              onChange={(value) => {
                formik.setFieldValue('acceptCaptcha', value !== null ? true : false)
              }}
            />
            {formik.touched.acceptCaptcha && formik.errors.acceptCaptcha && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.acceptCaptcha}</span>
                </div>
              </div>
            )}
          </div>
          <div className=''>
            <button
              className={`btn ${!formik.isValid ? 'btn-secondary' : 'btn-primary'} w-100`}
              type='submit'
              disabled={!formik.isValid}
            >
              {loading ? (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              ) : (
                'Bergabung'
              )}
            </button>
          </div>
          {/* end::Login Input Field */}
        </div>
        {/* end::Body */}

        {/* begin::Footer */}
        <div className='card-footer'>
          <div className='d-flex border-1 border-top pt-3'>
            <span className='me-2'>Sudah Punya Akun?</span>
            <Link className='text-primary fw-semibold fs-base' to='/login'>
              Masuk
            </Link>
          </div>
        </div>
        {/* begin::Footer */}
      </div>
    </form>
  )
}
