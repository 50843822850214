import React, {useEffect, useState} from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import {KTSVG, KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import OtpInput from 'react-otp-input'
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {setPassword} from '../core/_requests'

const initialValues = {
  otpCode: '',
  password: '',
  passwordConfirmation: '',
  acceptCaptcha: false,
}

const registrationSchema = Yup.object().shape({
  otpCode: Yup.string().required('Kode OTP wajib diisi'),
  password: Yup.string()
    .min(8, 'Password harus terdiri dari minimal 8 karakter')
    .required('Password wajib diisi')
    .matches(/[a-z]/, 'Minimal satu karakter lowercase')
    .matches(/[A-Z]/, 'Minimal satu karakter uppercase')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Minimal satu simbol'),
  passwordConfirmation: Yup.string()
    .min(8, 'Password harus terdiri dari minimal 8 karakter')
    .required('Password wajib diisi')
    .oneOf([Yup.ref('password')], 'Konfirmasi password tidak sesuai'),
  acceptCaptcha: Yup.bool().isTrue('reCAPTCHA wajib diisi'),
})

interface response {
  message: string
}

const SetPassword = () => {
  const [hasErrors, sethasErrors] = useState<unknown>()
  const [success, setSuccess] = useState('')
  const [loading, setLoading] = useState(false)
  const location: any = useLocation()
  const navigate = useNavigate()
  const [hiddenPass, setHiddenPass] = useState(true)
  const [hiddenPassConfirm, setHiddenPassConfirm] = useState(true)
  const [email, setEmail] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('payload')) {
      const payload: any = JSON.parse(atob(searchParams.get('payload') || ''))

      setEmail(payload?.email)
      formik.setFieldValue('otpCode', payload?.code)
    } else {
      navigate('/')
    }
  }, [searchParams.get('payload')])

  // formik
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      sethasErrors('')
      setSuccess('')
      try {
        const {data: response} = await setPassword(
          email,
          values.otpCode,
          values.password,
          values.passwordConfirmation
        )
        setLoading(false)
        setSuccess(response.message)
        setTimeout(() => {
          navigate('/')
        }, 2000)
      } catch (error) {
        setLoading(false)
        sethasErrors(error)
      }
    },
  })

  return (
    <form className='form w-100' onSubmit={formik.handleSubmit}>
      <div className='card card-custom card-flush bg-white'>
        {/* begin::body */}
        <div className='card-body'>
          {/* begin::Logo Title */}
          <div className='mb-10 text-center'>
            <img
              className='mb-3 w-md-50px w-40px'
              src={toAbsoluteUrl('/media/diawan/diawan-logo.png')}
              alt='diawan logo'
            />
            <h1 className='text-dark fw-bolder mb-3'>Atur Ulang Kata Sandi</h1>
          </div>
          {/* end::Logo Title */}

          {/* begin::Alert */}
          {hasErrors ? (
            <div className='alert alert-danger d-flex align-items-center gap-3' role='alert'>
              <KTSVG
                path='/media/icons/duotune/general/gen044.svg'
                className='svg-icon svg-icon-2x svg-icon-danger'
              />
              <div className='text-dark'>
                {(hasErrors as any).response.data.message ||
                  'Ada yang salah, silahkan coba lagi nanti'}
              </div>
            </div>
          ) : (
            ''
          )}

          {success && (
            <div className='alert alert-success d-flex align-items-center gap-3' role='alert'>
              <KTSVG
                path='/media/icons/duotune/general/gen043.svg'
                className='svg-icon svg-icon-2x svg-icon-success'
              />
              <div className='text-dark'>{success}</div>
            </div>
          )}

          {/* begin::Set Password Input Field */}

          <div className='mb-5'>
            <div className='form-floating'>
              <input
                id='email-input'
                type='email'
                placeholder='testing@example.com'
                value={email}
                className='form-control bg-transparent'
                readOnly
                disabled
              />
              <label htmlFor='email-input' className='form-label'>
                Email
              </label>
            </div>
          </div>

          <div className='mb-5'>
            <label className='mb-2 text-dark fw-semibold'>Kode Verifikasi</label>
            <div className='d-flex justify-content-center align-items-center mb-2'>
              <OtpInput
                value={formik.values.otpCode}
                onChange={(value) => formik.setFieldValue('otpCode', value)}
                numInputs={6}
                renderSeparator={<span className='mx-4'>-</span>}
                renderInput={(props) => <input {...props} className='form-control w-50px h-50px' />}
              />
            </div>
            {formik.touched.otpCode && formik.errors.otpCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.otpCode}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-5'>
            <div
              className={clsx(
                'input-group p-0 form-control bg-transparent',
                {
                  'border-danger': formik.touched.password && formik.errors.password,
                },
                {
                  'border-success': formik.touched.password && !formik.errors.password,
                }
              )}
            >
              <div className='form-floating'>
                <input
                  id='password-input'
                  type={hiddenPass ? 'password' : 'text'}
                  placeholder='password'
                  {...formik.getFieldProps('password')}
                  className='form-control border-0 bg-transparent'
                />
                <label htmlFor='password-input' className='form-label'>
                  Password
                </label>
              </div>
              <span
                className='input-group-text bg-transparent border-0'
                id='basic-addon2'
                onClick={() => setHiddenPass(!hiddenPass)}
              >
                <KTIcon
                  iconName={hiddenPass ? 'eye-slash' : 'eye'}
                  iconType='solid'
                  className='fs-1 cursor-pointer'
                />
              </span>
            </div>
            <div className='ms-2 mt-1 text-gray-600 fs-7'>
              Minimal 8 karakter dengan kombinasi huruf, numerik dan simbol.
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-5'>
            <div
              className={clsx(
                'input-group p-0 form-control bg-transparent',
                {
                  'border-danger':
                    formik.touched.passwordConfirmation && formik.errors.passwordConfirmation,
                },
                {
                  'border-success':
                    formik.touched.passwordConfirmation && !formik.errors.passwordConfirmation,
                }
              )}
            >
              <div className='form-floating'>
                <input
                  id='password-confirmation-input'
                  type={hiddenPassConfirm ? 'password' : 'text'}
                  placeholder='password'
                  {...formik.getFieldProps('passwordConfirmation')}
                  className='form-control border-0 bg-transparent'
                />
                <label htmlFor='password-confirmation-input' className='form-label'>
                  Konfirmasi Password
                </label>
              </div>
              <span
                className='input-group-text bg-transparent border-0'
                id='basic-addon2'
                onClick={() => setHiddenPassConfirm(!hiddenPassConfirm)}
              >
                <KTIcon
                  iconName={hiddenPassConfirm ? 'eye-slash' : 'eye'}
                  iconType='solid'
                  className='fs-1 cursor-pointer'
                />
              </span>
            </div>
            <div className='ms-2 mt-1 text-gray-600 fs-7'>
              Minimal 8 karakter dengan kombinasi huruf, numerik dan simbol.
            </div>
            {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.passwordConfirmation}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-5'>
            <ReCAPTCHA
              sitekey='6LeL-xIpAAAAANZJ0UTeyeUkvHIAUZTEWfKSajPn'
              // sitekey='6Lf3G_goAAAAAOR5mdkyZwLWiAIPBAb9FzwyslGI'
              onChange={(value) => {
                formik.setFieldValue('acceptCaptcha', value !== null ? true : false)
              }}
            />
            {formik.touched.acceptCaptcha && formik.errors.acceptCaptcha && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.acceptCaptcha}</span>
                </div>
              </div>
            )}
          </div>

          <div className=''>
            <button className='btn btn-primary w-100' type='submit'>
              {loading ? (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              ) : (
                'Bergabung'
              )}
            </button>
          </div>
          {/* end::Set Password Input Field */}
        </div>
        {/* end::Body */}

        {/* begin::Footer */}
        <div className='card-footer'>
          <div className='d-flex border-1 border-top pt-3'>
            <Link className='text-primary fw-semibold fs-base' to='/forgot-password'>
              Tidak menerima kode ?
            </Link>
          </div>
        </div>
        {/* begin::Footer */}
      </div>
    </form>
  )
}

export default SetPassword
