import {useState} from 'react'
import * as Yup from 'yup'
import {changePassword, requestPassword} from '../core/_requests'
import {useFormik} from 'formik'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import ReCAPTCHA from 'react-google-recaptcha'
import clsx from 'clsx'
import {useNavigate} from 'react-router-dom'

const initialValues = {
  email: '',
  acceptCaptcha: false,
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Email yang anda masukkan tidak valid').required('Email wajib diisi'),
  acceptCaptcha: Yup.bool().isTrue('Kamu harus menyetujui reCAPTCHA'),
})

interface response {
  message: string
}

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, sethasErrors] = useState<unknown>()
  const [response, setResponse] = useState<response>()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data} = await changePassword(values.email)
        setLoading(false)
        setResponse(data)
        navigate('/')
      } catch (error) {
        console.log(error)
        sethasErrors(error)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='card card-custom card-flush bg-white'>
        {/* begin::body */}
        <div className='card-body'>
          {/* begin::Logo Title */}
          <div className='mb-10 text-center'>
            <img
              className='mb-3 w-md-50px w-40px'
              src={toAbsoluteUrl('/media/diawan/diawan-logo.png')}
              alt='diawan logo'
            />
            <h1 className='text-dark fw-bolder mb-3'>Lupa Kata Sandi</h1>
          </div>
          {/* end::Logo Title */}

          {/* begin::Alert */}
          {hasErrors ? (
            <div className='alert alert-danger d-flex align-items-center gap-3' role='alert'>
              <KTSVG
                path='/media/icons/duotune/general/gen044.svg'
                className='svg-icon svg-icon-2x svg-icon-danger'
              />
              <div className='text-dark'>
                {(hasErrors as any).response.data.message ||
                  'Ada yang salah, silahkan coba lagi nanti'}
              </div>
            </div>
          ) : (
            ''
          )}

          {response && (
            <div className='alert alert-success d-flex align-items-center gap-3' role='alert'>
              <KTSVG
                path='/media/icons/duotune/general/gen043.svg'
                className='svg-icon svg-icon-2x svg-icon-success'
              />
              <div className='text-dark'>{response?.message}</div>
            </div>
          )}

          {/* begin::Login Input Field */}
          <div className='mb-5'>
            <div className='form-floating'>
              <input
                id='email-input'
                type='email'
                placeholder='email@example.com'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              <label htmlFor='floatingInput' className='form-label'>
                Email
              </label>
            </div>
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-5'>
            <ReCAPTCHA
              sitekey='6LeL-xIpAAAAANZJ0UTeyeUkvHIAUZTEWfKSajPn'
              // sitekey='6Lf3G_goAAAAAOR5mdkyZwLWiAIPBAb9FzwyslGI'
              onChange={(value) => {
                formik.setFieldValue('acceptCaptcha', value !== null ? true : false)
              }}
            />
            {formik.touched.acceptCaptcha && formik.errors.acceptCaptcha && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.acceptCaptcha}</span>
                </div>
              </div>
            )}
          </div>
          <div className=''>
            <button
              id='forgot-password=submit-button'
              className={`btn ${!formik.isValid ? 'btn-secondary' : 'btn-primary'} w-100`}
              type='submit'
              disabled={!formik.isValid}
            >
              {loading ? (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              ) : (
                'Kirim'
              )}
            </button>
          </div>
          {/* end::Login Input Field */}
        </div>
        {/* end::Body */}
      </div>
    </form>
  )
}
