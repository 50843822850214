/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate, useSearchParams} from 'react-router-dom'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {ForgotPassword} from '../modules/auth/components/ForgotPassword'
import {Login} from '../modules/auth/components/Login'
import {AuthLayout} from '../modules/auth/AuthLayout'
import SetPassword from '../modules/auth/components/SetPassword'
import {Registration} from '../modules/auth/components/Registration'
import {useCookies} from 'react-cookie'

const AppRoutes: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route element={<AuthLayout />}>
            <Route path='login' element={<Login />} />
            <Route path='registration' element={<Registration />} />
            <Route path='forgot-password' element={<ForgotPassword />} />
            <Route path='set-new-password' element={<SetPassword />} />
            <Route index element={<Login />} />
            <Route path='*' element={<Navigate to='/error/404' />} />
          </Route>
          <Route path='/error/*' element={<ErrorsPage />} />

          {/* <Route path='logout' element={<Logout />} /> */}
          {/* <Route path='auth/*' element={<AuthPage />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
