import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const LOCAL_API_URL = process.env.REACT_APP_LOCAL_API_URL
const API_TOKEN = process.env.REACT_APP_API_TOKEN

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGOUT_URL = `${API_URL}/logout`
export const LOGIN_URL = `${API_URL}/login`
export const VALIDATE_URL = `${API_URL}/email/activate`
export const REGISTER_URL = `${API_URL}/register`
export const CHANGE_PASSWORD_URL = `${API_URL}/otp/send`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const SET_NEW_PASSWORD_URL = `${API_URL}/password/forgot`
export const GET_PHONE_CODE_URL = `${API_URL}/phonecode`

export const CHANGE_EMAIL_CONFIRMATION_URL = `${API_URL}/email/confirm`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(
    LOGIN_URL,
    {
      email,
      password,
    },
    {
      headers: {
        'x-api-token': API_TOKEN,
      },
    }
  )
}

export function validate(email: string) {
  return axios.post(
    VALIDATE_URL,
    {
      email,
    },
    {
      headers: {
        'x-api-token': API_TOKEN,
      },
    }
  )
}

// Server should return AuthModel
export function register(
  email: string,
  fullName: string,
  phoneNumber: string,
  password: string,
  passwordConfirmation: string
) {
  return axios.post(
    REGISTER_URL,
    {
      name: fullName,
      email,
      password,
      password_confirmation: passwordConfirmation,
      phone_number: phoneNumber,
    },
    {
      headers: {
        'x-api-token': API_TOKEN,
      },
    }
  )
}

// change password HTTP Request
export function changePassword(email: string) {
  return axios.post(
    CHANGE_PASSWORD_URL,
    {email},
    {
      headers: {
        'x-api-token': API_TOKEN,
      },
    }
  )
}

// set new password HTTP Request
export function setPassword(
  email: string,
  code: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(
    SET_NEW_PASSWORD_URL,
    {email, code, password, password_confirmation},
    {
      headers: {
        'x-api-token': API_TOKEN,
      },
    }
  )
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function logout(token: string) {
  return axios.post(
    LOGOUT_URL,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export function changeEmailConfirmation(user_uuid: any) {
  return axios.post(
    CHANGE_EMAIL_CONFIRMATION_URL,
    {user_uuid},
    {
      headers: {
        'x-api-token': API_TOKEN,
      },
    }
  )
}

export function getPhoneCode() {
  return axios.get(GET_PHONE_CODE_URL, {
    headers: {
      'x-api-token': API_TOKEN,
    },
  })
}
